import { Fragment, ReactNode, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import TakeQuiz from "./components/modules/learners/quizPage/TakeQuiz";
import Result from "./components/modules/learners/Result";
import ModuleNavigation from "./components/homePage/ModuleNavigation";
import Learners from "./components/modules/learners/Learners";
import Trainings from "./components/modules/trainings/Trainings";
import Quizzes from "./components/modules/quizzes/Quizzes";
import Hrp from "./components/modules/hrp/Hrp";
import UserManagement from "./components/modules/userManagement/UserManagement";
// import LoginHandlers, { authProvider, processLogin } from "./LoginHandlers";

import { FaSpinner } from "react-icons/fa";
import AreaSubarea from "./components/modules/areaSubarea/AreaSubarea";

import { toast, ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./hooks/redux-hooks";
import { getRole } from "./store/commonActions";
import {
  API_FAILED,
  API_LOADING,
  API_SUCCEEDED,
  ROUTE_ACCESS,
} from "./utilities/helper/constants";
import {
  checkRoles,
  sessionGetter,
  sessionSetter,
} from "./utilities/helper/common";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "./msalConfig";
import { interactionInProgress } from "@azure/msal-browser/dist/error/BrowserAuthErrorCodes";

const App = () => {
  const { instance, inProgress, accounts } = useMsal();
  const dispatch = useAppDispatch();
  const isUserMsalAuthenticated = useIsAuthenticated();
  const { userRole, apiStatus } = useAppSelector(
    (store) => store.trainingNavigation
  );
  const [state, setState] = useState({
    isLoggedIn: false,
    isLoading: true,
    restrictUser: false,
  });
  const activeAccount: AccountInfo | undefined = accounts[0];
  const navigate = useNavigate();
  
  const handleUserAuthentication = async () => {
    if (!isUserMsalAuthenticated && inProgress === InteractionStatus.None) {
      navigate("/");
      await instance
        .ssoSilent(loginRequest)
        .then((response) => {
          instance.setActiveAccount(response.account);
          fetchUserDetails();
        })
        .catch((error) => {
          if(error instanceof InteractionRequiredAuthError)
            instance.loginRedirect(loginRequest);
        });
    }
    if (isUserMsalAuthenticated && inProgress === InteractionStatus.None) {
      fetchUserDetails();
    }
  };

  const loginUser = () => {
    setState({ ...state, isLoading: true });
    sessionStorage.setItem(
      "localpathname",
      window.location.pathname + window.location.search
    );
    handleUserAuthentication();
  };

  useEffect(() => {
    loginUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserMsalAuthenticated, instance, inProgress]);

  const fetchUserDetails = async (): Promise<void> => {
    try {
      const response = await instance
        .acquireTokenSilent({ ...loginRequest, account: activeAccount })
        .then((response: AuthenticationResult) => {
          return response;
        });

      if (response.accessToken && response.expiresOn) {
        const graph_data = await fetch("https://graph.microsoft.com/beta/me", {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .catch((err) => {
            toast.error(err);
          });
        sessionSetter("uid", graph_data?.onPremisesSamAccountName);
        sessionSetter("userName", graph_data?.displayName);
        checkUserAuthRole();
        if (response.account.username.endsWith("@network.lilly.com")) {
          // const mail = response.account.username;
          // const isAdmin = true;
          // const groups = [""];
          // const system_id = user_data.onPremisesSamAccountName;
          sessionSetter("token", response.accessToken);
          sessionSetter(
            "accessTokenExpiresOn",
            response.expiresOn.toLocaleTimeString()
          );
          sessionSetter("mail", response.account.username);
          setState({ ...state, isLoading: false, isLoggedIn: true });
          const localRoute = sessionStorage.getItem("localpathname");
          if (localRoute) {
            navigate(`${localRoute}`);
          } else if (!localRoute || localRoute === "null") {
            navigate("/");
          }
        }
      }
    } catch (error) {
      instance.loginRedirect(loginRequest);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     let isUserAuthenticated = await authProvider.isLoggedIn();
  //     if (isUserAuthenticated) {
  //       await processLogin();
  //       checkUserAuthRole();
  //     } else {
  //       await LoginHandlers();
  //       isUserAuthenticated = await authProvider.isLoggedIn();
  //       if (isUserAuthenticated) {
  //         await processLogin();
  //         checkUserAuthRole();
  //       } else {
  //         setState({ ...state, isLoggedIn: false, isLoading: true });
  //       }
  //     }
  //   })();
  // }, []);

  const checkUserAuthRole = async () => {
    try {
      await dispatch(getRole(sessionGetter("uid")));
    } catch (error) {
      setState({
        ...state,
        isLoggedIn: false,
        restrictUser: true,
        isLoading: false,
      });
      throw error;
    }
  };
  useEffect(() => {
    if (apiStatus === API_SUCCEEDED || apiStatus === API_FAILED) {
      if (userRole === "[]" || !checkRoles(JSON.parse(userRole))) {
        setState({
          ...state,
          restrictUser: true,
          isLoggedIn: true,
          isLoading: false,
        });
      } else
        setState({
          ...state,
          isLoggedIn: false,
          restrictUser: false,
          isLoading: false,
        });
    }
  }, [apiStatus]);

  function RolesAuthRoute({
    children,
    roles,
  }: {
    children: ReactNode;
    roles: Array<(typeof userRole)[number]>;
  }) {
    const userRole1 = JSON.parse(userRole);
    const canAccess = userRole1?.some((userRole: any) =>
      roles.includes(userRole)
    );

    if (canAccess) return <Fragment>{children}</Fragment>;

    return <Navigate to="/" />;
  }

  return state.isLoading || apiStatus === API_LOADING  ? (
    <div className="loader">
      <FaSpinner />
    </div>
  ) : state.restrictUser ? (
    <div className="unauthorized_Error">
      <h1>Unauthorized Access</h1>
      <p>
        You are not authorized to use this application. <br />
        Please raise access request through myAccess.
      </p>
    </div>
  ) : (
    <div className="iselectApp">
      <div className="background">
        <div className="innerBackground">
          <ModuleNavigation />
          <div className="contentNav">
            <Routes>
              <Route
                path="/trainings"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.trainings}>
                  <Trainings />
                  </RolesAuthRoute>
                }
              />
              <Route
                path="/"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.trainings}>
                    <Trainings />
                  </RolesAuthRoute>
                }
              />
              <Route
                path="/learners"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.learners}>
                    <Learners />
                  </RolesAuthRoute>
                }
              />

              <Route
                path="/quiz"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.quizzes}>
                    <Quizzes />
                  </RolesAuthRoute>
                }
              />

              <Route
                path="/usermanage"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.usermanage}>
                    <UserManagement />
                  </RolesAuthRoute>
                }
              />
              <Route
                path="/hrp"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.hrp}>
                    <Hrp />
                  </RolesAuthRoute>
                }
              />

              <Route
                path="/TakeQuiz"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.learners}>
                    <TakeQuiz />
                  </RolesAuthRoute>
                }
              />
              <Route
                path="/Result"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.learners}>
                    <Result />
                  </RolesAuthRoute>
                }
              />
              <Route
                path="/areaSubarea"
                element={
                  <RolesAuthRoute roles={ROUTE_ACCESS.usermanage}>
                    <AreaSubarea />
                  </RolesAuthRoute>
                }
              />
            </Routes>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          limit={1}
        />
      </div>
    </div>
  );
};
export default App;
