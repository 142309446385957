import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("iselectApp") as HTMLElement;
const root = createRoot(container);

export const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts()?.length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: { eventType: string; payload: any }) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    console.log("account", event.payload.account);
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
