import {
  PublicClientApplication,
  Configuration,
  RedirectRequest,
} from "@azure/msal-browser";

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_CLIENT_ID: string;
      REACT_APP_AUTHORITY: string;
      REACT_APP_REDIRECT_URI: string;
    }
  }
}

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ["USER.READ"],
};
